// import Swiper JS
import Swiper from 'swiper';
import {Navigation, Pagination} from 'swiper/modules';

// import Swiper styles
import 'swiper/css';

window.addEventListener('load', ()=> {
    initSlider();
});

window.addEventListener('orientationchange', ()=> {
    initSlider();
})

function initSlider() {
    const wrapperSwiper = document.querySelector('.certificates-block');

    if (wrapperSwiper) {
        const swiperElement = wrapperSwiper.querySelector('.swiper');
    
        const slider = new Swiper(swiperElement, {
            modules: [Navigation, Pagination],
            slidesPerView: 2,
            spaceBetween: 10,
            // observer: true,
            // observeParents: true,
            // centerInsufficientSlides: true,
            // autoHeight: true,
            // centeredSlides: true,
            // initialSlide: 1,
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
            },

            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                // dynamicBullets: true,
                // dynamicMainBullets: 3,
            },
        });
    
        slider;    
    }
}
